/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Get } from "../Api"

// reactstrap components
import { Table, Card, CardHeader, Spinner,Button, Container }
  from "reactstrap";
  import Moment from 'moment';




class Reports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      statusReport: 200

    };
  }

  componentDidMount = () => {
    this.reports();

  }

  reports = () => {

    this.setState({
      loading: true
    });



    Get("/admin/reports")
      .then(resp => {

        this.setState({
          loading: false,
          reportsData: resp


        });


      }).catch(error => {
        this.setState({
          statusReport: error.response ? error.response.status : 500,
          loading: false
        })

      });

  }


  render() {
    let result;
    if (this.state.loading) {

      result = <div className="text-center p-5" ><Spinner type="grow" color="primary" /></div>

    }
    else if (this.state.statusReport === 200 && this.state.reportsData !== undefined) {
      result = (

        
         
            
        <Card className="shadow p-5">
          <CardHeader>Reportes</CardHeader>
          <Table bordered className="align-items-center table-flush " responsive>

            <thead>

              <tr>
                <th >Causa</th>
                <th>Id del Mensaje</th>
                <th>Reportado</th>
                <th>Reportador</th>
                <th>Fecha</th>

              </tr>

            </thead>

            <tbody>
              {this.state.reportsData.length > 0 && this.state.reportsData.map(reportsData =>
                <tr>
                  <td >{reportsData.cause}</td>
                  <td>{reportsData.messageId}</td>
                  <td>{reportsData.nick}</td>
                  <td>{reportsData.nick_owner}</td>
                  <td>{Moment(reportsData.time).format('YYYY-MM-DD hh:mm:ss A')}</td>

                </tr>
              )}
            </tbody>

          </Table>

        </Card>
      )

    }

    else if (this.state.status !== 200) {
      result = <h1 className="text-center p-5">Error cargando los datos</h1>
    }


    return (
      <Container fluid>
      <div className="text-right mb-2 mt-2" >
      <Button  onClick={() => this.reports()} color="primary"> Refrescar</Button>
      </div>
      { result }
      </Container>
    );
  }
}



export default Reports;


