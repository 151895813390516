/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)

// react plugin used to create charts
import { Line } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  Container,
  Row,
  Col,
  Spinner,
  FormGroup,
  Label,
  Input,
  Form
} from "reactstrap";
import { Get } from '../Api'
import Qs from 'querystring'




class Chat extends React.Component {
  constructor(props) {
    super(props);


    this.state = {
      loading: true,
      status: 200,
      selectDay:7
    }


  }

  componentDidMount = () => {
    this.refresh();

  }


  refresh = (day=this.state.selectDay) => {

    this.setState({
      loading: true
    });

    let params = {
      "days": day,
      "timeZone": "-05:00"
    }


    Get("/admin/dashboard?" + Qs.stringify(params))
      .then(resp => {

        this.setState({
          loading: false,
          dashboardData: resp,
          status: 200
        });


      }).catch(error => {
        this.setState({
          status: error.response ? error.response.status : 500,
          loading: false
        })

      });

  }

  getDataToMessageByDay = () => {

    return {
      labels: this.state.dashboardData.messageByDay.labels,
      datasets: [
        {
          label: "Cantidad",
          data: this.state.dashboardData.messageByDay.data,
          fill: false,
          pointBorderColor: 'rgba(0,0,192,1)',
          pointBackgroundColor: 'rgba(0,0,192,0.8)',
          pointHoverRadius: 10,
          borderWidth: 2,
          borderColor: "#265fb1"
        }
      ]
    };
  }

  getOptionsToMessageByDay = () => {
    return {
      maintainAspectRatio: false,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,

          },
          gridLines: {
            display: true,
            color: "rgba(100, 100, 100, 0.1)"
          }
        }],
        xAxes: [
          {
            gridLines: {
              display: true,
              color: "rgba(100, 100, 100, 0.1)"
            }
          }
        ]
      }

    }

  }

  getDataToPronosticsByDay = () => {

    return {
      labels: this.state.dashboardData.pronosticsByDayMidday.labels, 
      datasets: [
        {
          label: "Mediodia ("+this.state.dashboardData.pronosticsByDayMidday.total+")",
          data: this.state.dashboardData.pronosticsByDayMidday.data,
          fill: false,
          pointBorderColor: 'rgba(0,0,192,1)',
          pointBackgroundColor: 'rgba(0,0,192,0.8)',
          pointHoverRadius: 10,
          borderWidth: 2,
          borderColor: "#265fb1"
        },
        {
          label: "Noche ("+this.state.dashboardData.pronosticsByDayEvening.total+")",
          data: this.state.dashboardData.pronosticsByDayEvening.data,
          fill: false,
          pointBorderColor: "#dc3545",
          pointBackgroundColor:  "#dc3545",
          pointHoverRadius: 10,
          borderWidth: 2,
          borderColor: "#dc3545"
        }
      ]
    };
  }

  
  getOptionsToPronosticsByDay = () => {
    return {
      maintainAspectRatio: false,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,

          },
          gridLines: {
            display: true,
            color: "rgba(100, 100, 100, 0.1)"
          }
        }],
        xAxes: [
          {
            gridLines: {
              display: true,
              color: "rgba(100, 100, 100, 0.1)"
            }
          }
        ]
      }

    }

  }

  onSelectDay = (e) =>{
    e.preventDefault();
     let day= e.target.value;
     console.log(day);
     this.setState({
      selectDay:day
    });
    this.refresh(day);
     
  }

  render() {

    let result;
    if (this.state.loading) {

      result = <div className="text-center p-5" ><Spinner type="grow" color="primary" /></div>

    }
    else if (this.state.status === 200 && this.state.dashboardData !== undefined) {
      result = (<>

        {/* Page content */}
        <Container fluid className="mb-5">
          <Row>
            <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="shadow">
                <CardHeader>
                  <Row className="align-items-center">
                    <div className="col">

                      <h4 className="mb-0">Mensajes ({this.state.dashboardData.messageByDay.total})</h4>
                    </div>

                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div className="chart">
                    <Line
                      data={this.getDataToMessageByDay()}
                      options={this.getOptionsToMessageByDay()}
                    />
                  </div>
                </CardBody>
              </Card>
               
              <Card className="shadow mt-5">
                <CardHeader>
                  <Row className="align-items-center" >
                    <div className="col">

                      <h5 className="mb-0">Pronosticos</h5>
                    </div>

                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div className="chart">
                    <Line
                      data={this.getDataToPronosticsByDay()}
                      options={this.getOptionsToPronosticsByDay()}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl="4" >
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h5 className="mb-0">Posiciones ({this.state.dashboardData.positions.dateMonthYear})</h5>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col"> </th>
                      <th scope="col">Nick</th>
                      <th scope="col">Puntos</th>
                      <th scope="col">Aciertos</th>
                    </tr>
                  </thead>
                 
                  <tbody>
                    
                  {this.state.dashboardData.positions.positions.length>0 && this.state.dashboardData.positions.positions.map(equipo =>   
                  <tr>   

               <td>{equipo.number}</td>             
               <td>{equipo.nick}</td> 
               <td>{equipo.points}</td>
               <td>{equipo.matchs}</td>
               </tr>
                  )}
             

                  </tbody>
                </Table>
              </Card>

              <Card className="shadow mt-5">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h5 className="mb-0">Personas con más mensajes</h5>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Nick</th>
                      <th scope="col">Mensajes</th>
                    </tr>
                  </thead>
                 
                  <tbody>
                    
                  {this.state.dashboardData.userActivosChat.length>0 && this.state.dashboardData.userActivosChat.map(equipo =>   
                  <tr>           
               <td>{equipo.nick}</td> 
               <td>{equipo.data}</td>
               </tr>
                  )}
             

                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
          
        </Container>
      </>)

    }

    else if (this.state.status !== 200) {
      result = <h1 className="text-center p-5">Error cargando los datos</h1>
    }


    return (
      <>
        <Container fluid className="mt-2 mb-2 ">
          <Row >


            <Col>
              <Form inline >
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                  <Label className="mb-2 mr-sm-2 mb-sm-0" for="selectDayID">Dias</Label>
                  <Input type="select" name="selectDay" id="selectDayID" onChange={(e)=>{this.onSelectDay(e)}} >
                    <option value="7">7 Dias</option>
                    <option value="14">14 Dias</option>
                    <option value="30">30 Dias</option>
                  </Input>
                </FormGroup>

                
              </Form>
            </Col>
            <Col className="text-right">
              <Button  onClick={() => this.refresh()} color="primary"> Refrescar</Button>
            </Col>

          </Row>

        </Container>


        {result}

      </>
    );


  }
}

export default Chat;
