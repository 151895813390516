/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import {Login} from "views/Login.js";
import Usuarios from "views/Usuario";
import Mensajes from "views/Mensajes";
import Chat from "views/Chat"
import Reports from "views/Reports"
import OAuth2Callback from "Api/oauth2callback"

//import Mensajes from "views/Mensajes";

var routes = [



  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin"
  }, 

  {
    path: "/chat",
    name: "Chat",
    icon: "fab fa-rocketchat text-blue",
    component: Chat,
    layout: "/admin"
  }, 


  {
    path: "/usuarios",
    name: "Usuarios",
    icon: "fas fa-user text-blue",
    component: Usuarios,
    layout: "/admin",
    visibility: "visible"
  },
  
/* {    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin"
  }, */
 {
    path: "/messages",
    name: "Mensajes",
    icon: "ni ni-bullet-list-67 text-blue",
    component: Mensajes,
    layout: "/admin"
 },

 {
  
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    visibility: "none"
 },

 {
  path: "/reports",
  name: "Reportes",
  icon: "fas fa-exclamation-triangle text-primary",
  component: Reports,
  layout: "/admin"
}, 
{
  path: "/oauth2callback",
  name: "oauth2",
  icon: "ni ni-key-25 text-info",
  component: OAuth2Callback,
  layout: "/auth",
  visibility: "none"
},

  /*,

    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  } */
];
export default routes;
