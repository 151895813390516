import React from 'react';
import { Table,Card,CardHeader } from 'reactstrap';
import Moment from 'moment';
//import { Test } from './MnesajesResientes.styles';

const MensajesRecientes = (props) => (


  <Card>
  <CardHeader>Mensajes</CardHeader>
  <Table bordered  className="align-items-center table-flush" responsive>


    <thead >

      <tr>
        <th>Id</th>
        <th>Fecha</th>
        <th>Mensajes</th>
      </tr>

    </thead>

    <tbody >
    {props.messages.length>0 && props.messages.map(messages => 
      
      <tr>
        <td style={{width:"10%",textOverflow:"ellipsis",wordWrap:"break-word", whiteSpace: "pre-wrap"}}>{messages.id}</td>
        <td >{Moment(messages.time).format('YYYY-MM-DD hh:mm:ss A')}</td>
        <td  style={{whiteSpace:"normal"}}>{messages.type==='gif' ? <img height='64' src={messages.message} alt='{messages.gif}'/>:messages.message}</td>
       
      </tr>
      )}
      {props.messages.length===0 && <tr><td colSpan="3" className="text-center">  NO EXISTEN MENSAJES</td></tr>}

    </tbody>

  </Table>

  </Card>


);

MensajesRecientes.propTypes = {
  // bla: PropTypes.string,
};

MensajesRecientes.defaultProps = {
  // bla: 'test',
};

export default MensajesRecientes;
