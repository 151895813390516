import React from 'react';
import {  Button, Table, Card, CardHeader,Spinner } from 'reactstrap';
import Moment from 'moment';
import { MdCached } from "react-icons/md"; 

//import { Test } from './MensajesTodos.styles';

const MensajesTodos = (props) => (
  <>
         
       <div className="text-right mb-2"> <Button onClick={(event)=>props.messagesAll(event)} color="primary"> <MdCached/> Refrescar</Button></div>
       {props.loading  &&  <div className="text-center mt-5 mb-5" ><Spinner type="grow" color="primary"/></div>}
       {props.status !==200 && <div className="text-center"><h4>Ha ocurrido un error!!</h4></div>}
{!props.loading  && props.messagesDataAll!==undefined && props.status ===200 && (<>
    <Card className="shadow">

      <CardHeader>Mensajes</CardHeader>

      <Table bordered className="align-items-center table-flush " responsive>

        <thead>

          <tr>
            <th >Id</th>
            <th>Nick</th>
            <th>Fecha</th>
            <th>Mensajes</th>
            <th>Like</th>
            <th>Dislike</th>
          </tr>

        </thead>
        <tbody>
          
          {props.messagesDataAll.length > 0 && props.messagesDataAll.map(messages =>
            <tr>

              <td>{messages.id}</td>
              <td>{messages.nickname}</td>
              <td>{Moment(messages.time).format('YYYY-MM-DD hh:mm:ss A')}</td>
              <td style={{ whiteSpace: "normal" }} >{messages.type==='gif' ? <img height='64' src={messages.message} alt='{messages.gif}'/>:messages.message}</td>
              <td>{messages.likes}</td>
              <td>{messages.dislikes}</td>

            </tr>
          )}
          
          
        </tbody>


      </Table>
    </Card>
    </>)}
  </>
 

);

MensajesTodos.propTypes = {
  // bla: PropTypes.string,
};

MensajesTodos.defaultProps = {
  // bla: 'test',
};

export default MensajesTodos;
