import React from 'react';
import {Card, CardHeader,  CardBody,FormGroup,Form,CustomInput,Button,Spinner, Alert, Input,Label} from 'reactstrap';
//import { Test } from './Administration.styles';

const Administration = (props) => {


  return (<Card className="shadow">
        <CardHeader>Administracion</CardHeader>
        <CardBody>
        {props.statusBlocked !==200 && <Alert color="danger">Ha ocurrido un error!!</Alert>}
        {props.loadingBlocked  &&  <div className="text-center mt-5 mb-5" ><Spinner type="grow" color="primary"/></div>}
        {!props.loadingBlocked && props.profile.blocked && (<div className="text-center mt-5 mb-5" >
        
        <h2>Usuario Bloqueado</h2> 
        {props.profile.blockedAll && <p>Pronosticos Bloqueados</p>}
        <Button  onClick={(e)=>{props.unblocked(e)}} className="rounded-lg mt-2" color="danger">Desbloquear</Button>
     
          
        </div>)
  }
   {!props.loadingBlocked && !props.profile.blocked &&  
            
        <div>
                <h3>Bloquear</h3>
                <Form>
                  <FormGroup className="mr-sm-2 mb-2">
                    <Label className="mb-2 mr-sm-2 mb-sm-0" for="selectCauseId">Causa</Label>
                    <Input style={{"width":"300px"}}type="select" name="selectCause" id="selectCauseId" onChange={(e)=>{props.handleInputChange(e)}} >
                    <option value="-1">-SELECCIONE-</option>
                      <option value="report_resultados_falsos">RESULTADOS FALSOS</option>
                      <option value="report_ofensas">OFENSAS </option>
                      <option value="report_vulgaridades">VULGARIDADES</option>
                      <option value="report_publicidad_terceros">PUBLCIDAD DE TERCEROS</option>
                      <option value="report_contenidos_no_afines">CONTENIDOS NO AFINES</option>
                      <option value="report_otro">OTROS</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <div>
                   
                      <CustomInput type="switch" id="mensajeBloquearId" label="bloquer mensajes" disabled checked />
                      <CustomInput type="switch" id="bloquearPronosticoId" name="bloquearPronostico" label="Bloquear pronostico" onChange={(event)=> props.handleInputChange(event)}  /> 
                      <CustomInput type="switch" id="bloquearDispositivoId" name="bloquearDispositivo" label="Bloquear dispositivo"  onChange={(event)=> props.handleInputChange(event)} />
                      <CustomInput type="switch" id="enviarNotificacionId" name="enviarNotificacion" label="Enviar notificación"  onChange={(event)=> props.handleInputChange(event)} />
                    </div>
                    <Button onClick={(e)=>{props.blocked(e)}} className="rounded-lg mt-2" color="danger">Bloquear</Button>
                         
                  </FormGroup>
                </Form>
          
         </div>  
   }
      </CardBody>
    </Card>)
                
      
  
  }
  


Administration.propTypes = {
  // bla: PropTypes.string,
};

Administration.defaultProps = {
  // bla: 'test',
};

export default Administration;
