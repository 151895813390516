import React from 'react';
import { Form,FormGroup,Button, Table,Card, CardHeader, Input,Spinner, Alert} from 'reactstrap';
import { MdSearch } from "react-icons/md";
import Moment from 'moment';
//import { Test } from './MensajesPorUsuarios.styles';

const MensajesPorUsuarios = (props) => (

 

  <>
  <Form inline onSubmit ={(event)=>props.messagesByUser(event)}>
    <FormGroup className= "mr-2" >



      <Input type="search" name="nick" id="nickId" placeholder="Nick"  onChange={(event)=> props.handleInputChange(event)} />

    </FormGroup>
    <Button onClick={(event)=>props.messagesByUser(event)} color="primary"> <MdSearch/> Buscar</Button>
 
  </Form>

  {props.statusDelete ===500? <Alert color="danger"  className="w-50 mt-5 text-center" style={{margin:"auto"}}>Ha ocurrido un error eliminando!!</Alert>:props.statusDelete ===404 && <Alert color="warning" className="w-50 mt-5 text-center" style={{margin:"auto"}}>No se ha eliminado ningún mensajes!!</Alert>}
   {props.statusDelete ===200 && props.deleteMessagesData!==undefined && <Alert color="success" className="w-50 mt-5 text-center" style={{margin:"auto"}}>Se han eliminado {props.deleteMessagesData.deleted} mensajes!!</Alert>}

  {props.status ===500?<div className="text-center"> <h4 color="danger">Ha ocurrido un error!!</h4></div>:props.status ===404 && <div className="text-center"><h4 color="danger">El usuario no tiene mensajes!!</h4></div>}
  {props.loading  &&  <div className="text-center mt-5 mb-5" ><Spinner type="grow" color="primary"/></div>}
  {!props.loading  && props.messagesData!==undefined && props.status === 200 && <>
  
  <Button onClick={(event)=>props.deleteMessagesByUser(event)} color="danger" className="mt-3 mb-3">  Eliminar</Button>
  

  
<Card className="shadow">


<CardHeader>Mensajes por Usuario</CardHeader>

<Table bordered className="align-items-center table-flush " responsive>

<thead> 

<tr>
  <th >Id</th>
  <th>Nick</th>
  <th>Fecha</th>
  <th>Mensajes</th>
  <th>Like</th>
  <th>Dislike</th>
</tr>

</thead>
<tbody>
{props.messagesData.length>0 && props.messagesData.map(messages =>
<tr>

    <td>{messages.id}</td>
    <td>{messages.nickname}</td>
    <td>{ Moment(messages.time).format('YYYY-MM-DD hh:mm:ss A')}</td>
    <td style={{ whiteSpace: "normal" }} >{messages.type==='gif' ? <img height='64' src={messages.message} alt='{messages.gif}'/>:messages.message}</td>
    <td>{messages.likes}</td>
    <td>{messages.dislikes}</td>
   
  </tr>
 )}
  </tbody>


</Table>


</Card>
</>
 
}</>


);

MensajesPorUsuarios.propTypes = {
  // bla: PropTypes.string,
};

MensajesPorUsuarios.defaultProps = {
  // bla: 'test',
};

export default MensajesPorUsuarios;
