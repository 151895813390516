
import axios from "axios";
import url from "url";
import { history } from '../security/_helpers';

let get  = null;
let post = null;
let put  = null;
let del  = null;

let urlBase = process.env.REACT_APP_API_HOST;//"http://localhost:8080";

axios.defaults.timeout = 60000;

const getHeaders = () => {
    let user = JSON.parse(localStorage.getItem('user'));
    let headers = {
        'Accept': 'application/json;charset=UTF-8',
        'Content-Type': 'application/json;charset=UTF-8'
    };

    if(user !== null) {
        headers["x-api-key"] = user.token
    }
    
    return headers;

};

get  = (endpoint, opt = {}) => {
    const ep = url.resolve(urlBase, endpoint);
    return axios.get(ep, {params: opt.queryStringParameters, headers:getHeaders()})
    .then(resp => resp.data)
    .catch(error => {
        if(error.response && error.response.status === 403) {
            history.push('/auth/login');
        }
        else {
            return Promise.reject(error);
        }
    });
};

post = (endpoint, body) => {
    const ep = url.resolve(urlBase, endpoint);
    return axios.post(ep, body, {headers:getHeaders()})
    .then(resp => resp.data)
    .catch(error => {
        if(error.response && error.response.status === 403) {
            history.push('/auth/login');
        }
        else {
            return Promise.reject(error);
        }
    });
};

put  = (endpoint, body,opt = {}) => {
    const ep = url.resolve(urlBase, endpoint);
    return axios.put(ep, body, {params: opt.queryStringParameters, headers:getHeaders()})
    .then(resp => resp.data)
    .catch(error => {
        if(error.response && error.response.status === 403) {
            history.push('/auth/login');
        }
        else {
            return Promise.reject(error);
        }
    });
};

del  = (endpoint, opt = {}) => {
    const ep = url.resolve(urlBase, endpoint);
    return axios.delete(ep, {params: opt.queryStringParameters, headers:getHeaders()})
    .then(resp => resp.data)
    .catch(error => {
        if(error.response && error.response.status === 403) {
            history.push('/auth/login');
        }
        else {
            return Promise.reject(error);
        }
    });
};



export const Get  = get;
export const Post = post;
export const Put  = put;
export const Del  = del;
