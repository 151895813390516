
import { Post } from '.././../Api';

export const userService = {
    login,
    logout

};

function login(username, password) {

    let body = {
        "username": username,
        "password": password

    }


    return Post("/auth/login", body)
    
        .then(resp => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(resp));

            return resp;
        })
        .catch(error=>{
            let status =error.response?error.response.status:500;
            if (status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.reload(true);
            }

            return Promise.reject(error);
        });

}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

