import React from 'react';
import {Card, CardHeader,  CardBody, Button,Alert} from 'reactstrap';
//import { Test } from './Profile.styles';

const Profile = (props) => (

  <Card className="shadow">
     
     
    <CardHeader>Perfil</CardHeader>
    <CardBody >
    {props.statusTag !==200 && <Alert color="danger">Ha ocurrido un error!!</Alert>}
   
      <p>
        <strong>Nick:</strong>
        <span className="font-weight-light ml-2">{props.profile.nick}</span>
      </p>
      
      <p>
        <strong>Pin:</strong>
        <span className="font-weight-light ml-2">{props.profile.pin}</span>
      </p>
      <p>
        
      <strong>Tag:</strong>

      <span className="font-weight-light ml-2">{props.profile.tag?props.profile.tag:(<Button onClick={()=>props.updateTag()} color="primary" size="sm">Ascender</Button>)}</span>
      
      </p>
      <p>
      <strong>CreatedAt:</strong>
        <span className="font-weight-light ml-2">{props.profile.createdAt}</span>
      </p>
      <p>
        <strong>uuid:</strong>
        <span className="font-weight-light ml-2">{props.profile.uuid}</span>
      </p>
      <p>
        <strong>Postales:</strong>
        <span className="font-weight-light ml-2">{props.postalesCount}</span>
      </p>
     
     {/*  <p>
      <strong>Premios:</strong>
        <span className="font-weight-light ml-2">{props.profile.Premios}</span>
      </p> */}
    </CardBody>
     
    
  </Card>





);

Profile.propTypes = {
  // bla: PropTypes.string,
};

Profile.defaultProps = {
  // bla: 'test',
};

export default Profile;
