/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)

// react plugin used to create charts

// reactstrap components
import {
  Row,
  Col,
 Button,
 Container
} from "reactstrap";
import { Get, Post } from '../Api'
import Number from "components/Dashboard/Number"
import confirm from "reactstrap-confirm";
import { WiDaySunny, WiNightClear } from 'react-icons/wi';
import Moment from "moment";
import localization from 'moment/locale/es'
import { MdCached } from "react-icons/md";
import dashboard from "../assets/img/dashboard.png";


class Index extends React.Component {
  constructor(props) {
    super(props);


    this.state = {
      loading: true,
      statusNumber: 200,
      statusInput: 0,
      date: new Date().toISOString()
    }


  }

  validateConfirm = () => {

    let validate = true;

    if (!this.state.date) {
      validate = false;
    }

    var fijoReg = /\d{3}/;
    var corridoReg = /\d{2}/;

    if (!fijoReg.test(this.state.fijo)) {
      validate = false
    }

    if (!corridoReg.test(this.state.corrido1)) {
      validate = false
    }

    if (!corridoReg.test(this.state.corrido2)) {
      validate = false
    }

    if (this.state.stacion !== "midday" && this.state.stacion !== "evening") {
      validate = false
    }


    if (!validate) {
      alert("campos invalidos");
    }

    return validate;

  } 


  numberConfirm = async () => {

    
    let result = await confirm({
      title: (

        <strong>Confirmación</strong>

      ),
      message: (
        <>
          <div style={{ color: "red", fontSize: "30px", textAlign: "center", fontStyle: "bold" }}>Revise bien los numeros</div>
          <hr />
          <Col style={{ fontSize: "25px", fontweight: "bold", textAlign: "center" }}> {Moment.utc(this.state.date).locale('es', localization).format("D [de] MMMM [del] YYYY ")} </Col>
          <hr />
          {this.state.stacion === "midday" ? <Col style={{ fontSize: "30px", fontweight: "bold", textAlign: "center" }}> <WiDaySunny fontSize="60" /> {this.state.fijo} - {this.state.corrido1} - {this.state.corrido2}</Col> : null}

          {this.state.stacion === "evening" ? <Col style={{ fontSize: "30px", fontweight: "bold", textAlign: "center" }}> <WiNightClear fontSize="60" /> {this.state.fijo} - {this.state.corrido1} - {this.state.corrido2} </Col> : null}


        </>
      ),

      confirmText: (<div onClick={(e) => { this.inputNumber(e) }}>Aceptar</div>),
      cancelText: "Cancelar"
    });

    if (result) {
      this.setState({

        status: 200
      });

    }

    return true;

  }


  handleInputChange = (event) => {

    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState({
      [name]: value
    });

  }



  componentDidMount = () => {
    this.getNumber();

  }



  handleChangeDate = (value, formattedValue) => {
    this.setState({
      date: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
      formattedValue: formattedValue // Formatted String, ex: "11/19/2016"
    })
  }


  inputNumber = (event) => {

    this.setState({
      loading: true,
      statusInput: 0,
    });
    console.log(this.state );
    let body = {
      "centenaDia": this.state.stacion === "midday" ? this.state.fijo : null,
      "corrido1Dia": this.state.stacion === "midday" ? this.state.corrido1 : null,
      "corrido2Dia": this.state.stacion === "midday" ? this.state.corrido2 : null,
      "centenaNoche": this.state.stacion === "evening" ? this.state.fijo : null,
      "corrido1Noche": this.state.stacion === "evening" ? this.state.corrido1 : null,
      "corrido2Noche": this.state.stacion === "evening" ? this.state.corrido2 : null,
      "fecha": this.state.date,
      "type": this.state.stacion
    }
    console.log(body);

    Post("/admin/number", body)
      .then(() => {
        
        this.setState({
          statusInput: 201,
          loading: false
        });

      }).catch(error => {
        this.setState({
          statusInput: error.response ? error.response.status : 500,
          loading: false
        })

      });
     
  }

  refrescar = (e) => {

  this.getNumber();

  }


  getNumber = () => {

    this.setState({
      loading: true,
      statusInput:0
    });


    Get("/admin/number")
      .then(resp => {

        this.setState({
          loading: false,
          numberData: resp,
          statusNumber: 200
        });
        

      }).catch(error => {
        this.setState({
          statusNumber: error.response ? error.response.status : 500,
          loading: false
        })

      });

  }


  render() {



    return (<>

    <Container fluid className="mt-3 mb-5">

    <Row className=" text-right">

      <Col>
         <Button color="primary" onClick={(e)=>this.refrescar(e)}><MdCached/> Refrescar</Button>

    </Col>
      </Row>

      <Row className="mt-3">
        <Col xs="7">
          <Row>
            <Col xs="3" className="text-right"><img src={dashboard} alt="Dashboard" width="128" height="128"/></Col>
            <Col xs="9">
              <h2>Administracion La Bolita</h2>
              <p>Binevenidos al sitio de administración de La Bolita.</p>
            </Col>
          </Row>
        </Col>

        <Col xs="5">
          <Number  validateConfirm={this.validateConfirm} statusInput={this.state.statusInput} date={this.state.date} formattedValue={this.state.formattedValue} handleChangeDate={this.handleChangeDate} statusNumber={this.state.statusNumber} loading={this.state.loading} numberData={this.state.numberData} handleInputChange={this.handleInputChange} numberConfirm={this.numberConfirm} inputNumber={this.inputNumber} />
        </Col>

      </Row>
      </Container>


    </>
    );

  }

}



export default Index;
