import React, { PureComponent} from 'react';
import { Row, Button, Container, Col, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MensajesPorId from '../../components/Mensajes/MensajesPorId';
import MensajesPorUsuarios from '../../components/Mensajes/MensajesPorUsuarios';
import MensajesPorTexto from '../../components/Mensajes/MensajesPorTexto';
import MensajesTodo from '../../components/Mensajes/MensajesTodos';
import { Get, Del,Post } from '../../Api'
import Qs from 'querystring'
import confirm from "reactstrap-confirm";

//import { Test } from './Mensajes.styles';

class Mensajes extends  PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      messagesType: "all",
      loading: false,
      status: 200,
      statusDelete: 200,
      modal:false
      
    };


  }
  
  


  
  componentDidMount = () => {
   
    this.messagesAll();
    

  }


  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      [name]: value
    });
  }



  messagesButton = (type) => {
    this.setState({
      messagesType: type,
      messagesData: undefined,
      status: 200,
      deleteMessagesData:undefined
    });

  }


  deleteMessagesByUser = async() => {
 
    let result = await confirm({
      title: (
      
       <strong>Confirmación</strong>
          
      ),
      message: "¿Estás seguro que deseas eliminar los mensajes?",
      confirmText: "Aceptar",
      cancelText: "Cancelar"
  });

    if (result){
      this.setState({
      loading: true,
      status: 200
    });
    let paramsNick = {
      "nick": this.state.nick

    }

    Del("/admin/messages/?" + Qs.stringify(paramsNick))
      .then(resp => {

        this.setState({
          loading: false,
          messagesData: undefined,
          status: 200,
          statusDelete: 200,
          deleteMessagesData:resp
        });

      }).catch(error => {
        this.setState({
          statusDelete: error.response ? error.response.status : 500,
          loading: false,
          status: 200
        })
     
      });
    }
  }

  


  deleteMessagesById = async() => {
 
    let result = await confirm({
      title: (
      
       <strong>Confirmación</strong>
          
      ),
      message: "¿Estás seguro que deseas eliminar los mensajes?",
      confirmText: "Aceptar",
      cancelText: "Cancelar"
  });

    if (result){
      this.setState({
      loading: true,
      status: 200
    });
    let paramsById = {
      "id": this.state.messageId

    }

    Del("/admin/messages/?" + Qs.stringify(paramsById))
      .then(resp => {

        this.setState({
          loading: false,
          messagesData: undefined,
          status: 200,
          statusDelete: 200,
          deleteMessagesData:resp
          
        });

      }).catch(error => {
        this.setState({
          statusDelete: error.response ? error.response.status : 500,
          loading: false,
          status: 200
        })
     
      });
    }
  }



  deleteMessagesByQuery = async() => {
 
    let result = await confirm({
      title: (
      
       <strong>Confirmación</strong>
          
      ),
      message: "¿Estás seguro que deseas eliminar los mensajes?",
      confirmText: "Aceptar",
      cancelText: "Cancelar"
  });

    if (result){
      this.setState({
      loading: true,
      status: 200
    });
    let paramsByQuery = {
      "query": this.state.query

    }

    Del("/admin/messages/?" + Qs.stringify(paramsByQuery))
      .then(resp => {

        this.setState({
          loading: false,
          messagesData: undefined,
          status: 200,
          statusDelete: 200,
          deleteMessagesData:resp
        });

      }).catch(error => {
        this.setState({
          statusDelete: error.response ? error.response.status : 500,
          loading: false,
          status: 200
        })
     
      });
    }
  }


  messagesByUser = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
      status: 200,
      deleteMessagesData:undefined
    });
    let paramsNick = {
      "nick": this.state.nick,
      "from": 0,
      "size": 10000
    }

    Get("/admin/messages/?" + Qs.stringify(paramsNick))
      .then(resp => {

        this.setState({
          loading: false,
          messagesData: resp,

        });



      }).catch(error => {
        this.setState({
          status: error.response ? error.response.status : 500,
          loading: false,

        })

      });

  }

  messagesById = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
      status: 200,
      deleteMessagesData:undefined

    });

    let paramMessageById = {
      "id": this.state.messageId,

    }

    Get("/admin/messages/?" + Qs.stringify(paramMessageById))
      .then(resp => {

        this.setState({
          loading: false,
          messagesData: resp,
          

        });



      }).catch(error => {
        this.setState({
          status: error.response ? error.response.status : 500,
          loading: false,

        })

      });

  }


  messagesAll = (e) => {
   
    this.setState({
      loading: true,
      status: 200

    });

   
    Get("/admin/messagesall")
      .then(resp => {

        this.setState({
          loading: false,
          messagesDataAll: resp,
          status:200
        });



      }).catch(error => {
        this.setState({
          status: error.response ? error.response.status : 500,
          loading: false
        })

      });

  }


  messagesByQuery = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
      status: 200

    });

    let paramsQuery = {
      "query": this.state.query,
      "from": 0,
      "size": 10000
    }


    Get("/admin/messages/?" + Qs.stringify(paramsQuery))
      .then(resp => {

        this.setState({
          loading: false,
          messagesData: resp,
          deleteMessagesData:undefined

        });



      }).catch(error => {
        this.setState({
          status: error.response ? error.response.status : 500,
          loading: false
        })

      });

  }

  sendMessages = () => {

    

    let validate = true;
    if(this.state.role !=="admin" && this.state.role !== "chatbot") {
      validate = false;
    }
    if(!this.state.tittle || !this.state.message) {
      validate = false;
    }

    if(!validate) {
      alert("Campos invalidos!!.");
      return;
    }

    this.setState({modal:false});


    let body = {
      "role": this.state.role,
      "title":this.state.tittle,
      "text": this.state.message

    }

    Post("/admin/sendmessageschat", body)
      .then(() => {
        
        this.setState({modal:false});
        alert("Se envio el mensaje correctamente!!!.");

      }).catch(error => {
                 
        alert("Ha ocurrido un error enviando el mensaje!!.");
      });

  }
  



  render() {
   
    return (
            
      <Container fluid className="mt-5 mb-5">
        <Row>
          <Col>
             <Button onClick={(e) => this.messagesButton("all")} color="primary">Mensajes</Button>
            <Button onClick={(e) => this.messagesButton("id")} color="primary">Mensajes por id</Button>
            <Button onClick={(e) => this.messagesButton("texto")} color="primary">Mensajes por texto </Button>
            <Button onClick={(e) => this.messagesButton("user")} color="primary">Mensajes por usuario</Button>
            <Button onClick={()=>this.setState({modal:true,tittle:"",role:null,message:""})} color="info">Enviar Mensajes</Button>
            <span>
           <Modal style={{  fontweight: "bold"  }} isOpen={this.state.modal} >
           <ModalHeader  toggle={()=>this.setState({modal:false})} >Enviar Mensaje</ModalHeader>
           <ModalBody className = "text-center">
           
           <Form  inline >
           
         
             <FormGroup inline className="pl-8" >
               
               <Label>
                  <Input onChange={(event)=> this.handleInputChange(event)} type="radio" checked={this.state.role==="admin"} value="admin" name="role" /> Admin
              </Label>
         
         <Label className="ml-4">
            <Input onChange={(event)=> this.handleInputChange(event)}  type="radio" checked={this.state.role==="chatbot"} value="chatbot" name="role" /> ChatBot
         </Label>
       </FormGroup>
      
        
                  <hr/>
            
               <FormGroup inline className="p-3" > 
                 <Label style={{fontSize:"20px"}} className="pr-6" for="tittleId">Titulo</Label>
                 <Input type="text" name="tittle" autoComplete="off" id="tittleId" style={{width:"200px"}} value={this.state.tittle} onChange={(event)=> this.handleInputChange(event)}  />
               </FormGroup>
               
               <FormGroup className="p-3"> 
                 <Label style={{fontSize:"20px"}}  className="pr-5" for="messageId">Mensaje</Label>
                 <Input type="textarea"autoComplete="off" name="message" id="messageId" style={{width:"200px"}} value={this.state.message} onChange={(event)=> this.handleInputChange(event)}  />
               </FormGroup>
               

         </Form>
         </ModalBody>
         <ModalFooter>
           <Button color="primary" onClick={()=> this.sendMessages()}>Aceptar</Button>{' '}
           <Button color="secondary"  onClick={()=>this.setState({modal:false})}>Cancel </Button>
         </ModalFooter>
       </Modal>
       
     </span>
           
          </Col>
        </Row>
        <hr />

        <Row>
          <Col>
                      

            {this.state.messagesType ==="all" && <MensajesTodo status={this.state.status} messagesAll={this.messagesAll} messagesDataAll={this.state.messagesDataAll} loading={this.state.loading}/>}
        
            {this.state.messagesType === "id" && <MensajesPorId messagesById={this.messagesById}
             handleInputChange={this.handleInputChange} 
             messagesData={this.state.messagesData} loading={this.state.loading} 
             status={this.state.status}
             deleteMessagesById={this.deleteMessagesById}
             statusDelete ={this.state.statusDelete} 
             deleteMessagesData = {this.state.deleteMessagesData}  />}

            {this.state.messagesType === "user" && <MensajesPorUsuarios messagesByUser={this.messagesByUser}
             handleInputChange={this.handleInputChange}
              messagesData={this.state.messagesData} 
              loading={this.state.loading}
               status={this.state.status}
               deleteMessagesByUser={this.deleteMessagesByUser}
              statusDelete ={this.state.statusDelete}
              deleteMessagesData = {this.state.deleteMessagesData}  />}
                
            {this.state.messagesType === "texto" && <MensajesPorTexto messagesByQuery={this.messagesByQuery}
             handleInputChange={this.handleInputChange}
            messagesData={this.state.messagesData}
             loading={this.state.loading} status={this.state.status}
             deleteMessagesByQuery={this.deleteMessagesByQuery}
             statusDelete ={this.state.statusDelete}
             deleteMessagesData = {this.state.deleteMessagesData}  />}

          
            
          </Col>

        </Row>

        

      </Container>

    );
  }
}

Mensajes.propTypes = {
  // bla: PropTypes.string,
};

Mensajes.defaultProps = {
  // bla: 'test',
};

export default Mensajes;
