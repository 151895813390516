import React from 'react';
import { Table,Card, CardHeader } from 'reactstrap';
import {WiDaySunny,WiNightClear } from 'react-icons/wi';
import Moment from 'moment';


//import { Test } from './PronosticoReciente.styles';

const PronosticosRecientes = (props) => (

  <Card className="shadow"> 
    <CardHeader>Pronosticos Recientes</CardHeader>
  
    <Table bordered className="align-items-center table-flush" responsive>
  
        <thead> 
        
        <tr>
          <th >Estacion</th>
          <th>Creado</th>
          <th>Fecha</th>
          <th>Pronosticos</th>
        </tr>

        </thead>
        
       
        <tbody>
        {props.pronostics.length>0 && props.pronostics.map(pronostics => 
      
      <tr>
        <td >{pronostics.station=== "midday"?<WiDaySunny fontSize="40" />:<WiNightClear fontSize="40" />}</td>
        <td>{ Moment(pronostics.creado).format('YYYY-MM-DD hh:mm:ss A')}</td>
        <td>{pronostics.fecha}</td>
        <td  style={{whiteSpace:"normal"}}>{pronostics.pronostics1} {pronostics.pronostics2?'; '+pronostics.pronostics2:null} {pronostics.pronostics3?'; '+pronostics.pronostics3:null} {pronostics.pronostics4?'; '+pronostics.pronostics4:null} {pronostics.pronostics5?'; '+pronostics.pronostics5:null}</td>
        
      </tr>)}
      {props.pronostics.length===0 && <tr><td colSpan="4" className="text-center"> NO EXISTEN PRONOSTICOS</td></tr>}

      </tbody>

    </Table>
    </Card>
)

PronosticosRecientes.propTypes = {
  // bla: PropTypes.string,
};

PronosticosRecientes.defaultProps = {
  // bla: 'test',
};

export default PronosticosRecientes;
