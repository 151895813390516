import React,{useState} from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Alert,
  Row,
  Col,
  Spinner,
  Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
//import { Test } from './Number.styles';
import Moment from "moment";
import localization from 'moment/locale/es'
import { WiDaySunny, WiNightClear } from 'react-icons/wi';
import DatePicker from "reactstrap-date-picker";



const Number = (props) => {

 
  const [modal, setModal] = useState(false);
  



  return (<Card className="shadow text-center ">
        <CardHeader><h5>Números</h5></CardHeader>
        
        <CardBody>
        <Alert color="danger" isOpen={props.statusNumber !==200}>Ha ocurrido un error!!</Alert>
        {props.loading &&  <div className="text-center mt-5 mb-5" ><Spinner type="grow" color="primary"/></div>}

        <Alert color="success" isOpen={props.statusInput===201}>Se lanzo el numero correctamente!!</Alert> 
        <Alert color="danger" isOpen={props.statusInput!==201 && props.statusInput!==0}>Ha ocurrido un error lanzando!!</Alert>  
       
   {!props.loading && props.statusNumber ===200 &&  
            
        <div>

      
          

      <Row>
        <Col className="text-center" colSpan="4" style={{ fontSize: "24px", fontweight: "bold" }}>   {Moment.utc(props.numberData.date).locale('es', localization).format("D [de] MMMM [del] YYYY ")}</Col>
      </Row>
      
     <hr/>

        <Row>
      <Col style={{ fontSize: "30px", fontweight: "bold" }}> <WiDaySunny fontSize="60" /> {props.numberData.pick3Midday} - {props.numberData.pick4Midday[0]}{props.numberData.pick4Midday[1]} - {props.numberData.pick4Midday[2]}{props.numberData.pick4Midday[3]}</Col>
        </Row>
      {props.numberData.pick3Evening?<Col style={{ fontSize: "30px", fontweight: "bold" }}> <WiNightClear fontSize="60" /> {props.numberData.pick3Evening} - {props.numberData.pick4Evening[0]}{props.numberData.pick4Evening[1]} - {props.numberData.pick4Evening[2]}{props.numberData.pick4Evening[3]}</Col>:
      <Col style ={{ fontSize: "30px", fontweight: "bold" }}> <WiNightClear fontSize="60" /> (Sale a las 9pm)</Col>}
      <Row>
  
      </Row>


      <span>
        <Button onClick={() => {setModal(true)} } color="primary" size="sm"> Lanzar </Button>
        <Modal style={{  fontweight: "bold"  }} isOpen={modal} >
          <ModalHeader  toggle={()=> setModal(false)} >Lanzar Manualmente</ModalHeader>
          <ModalBody className = "text-center">
        
          <Form  inline >
          
        <FormGroup   inline className="p-3" >
        <Label  className="pr-2" check>Fecha</Label>
        <DatePicker id      = "datepicker" 
                    value   = {props.date} 
                    onChange= {(v,f) => props.handleChangeDate(v, f)}
                    style = {{width: "0%"}}
                    />
      </FormGroup>
     
        
            <FormGroup inline className="pl-8" >
              
              <Label>
                 <Input onChange={(event)=> props.handleInputChange(event)} type="radio" value="midday" name="stacion" /> Mediodia
             </Label>
        
        <Label className="ml-4">
           <Input onChange={(event)=> props.handleInputChange(event)}  type="radio" value="evening" name="stacion" /> Noche
        </Label>
      </FormGroup>
     
       
                 <hr/>
           
              <FormGroup inline className="p-3" > 
                <Label style={{fontSize:"20px"}} className="pr-6" for="id">Fijo</Label>
                <Input type="text" name="fijo" autoComplete="off" id="fijoID" style={{width:"200px"}} maxLength="3"  onChange={(event)=> props.handleInputChange(event)}  />
              </FormGroup>
   
              <FormGroup className="p-3"> 
                <Label style={{fontSize:"20px"}}  className="pr-4" for="corriodo1ID">Corrido  1</Label>
                <Input type="text" autoComplete="off" name="corrido1" id="corriodo1ID" style={{width:"200px"}} maxLength="2"  onChange={(event)=> props.handleInputChange(event)}  />
              </FormGroup>
              
              <FormGroup className="p-3"> 
                <Label style={{fontSize:"20px"}} className="pr-3" for="corrido2ID">Corrido 2</Label>
                <Input type="text"  name="corrido2" id="corrido2ID" style={{width:"200px"}}  maxLength="2" autoComplete="off" onChange={(event)=> props.handleInputChange(event)}   />
              </FormGroup>
        </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary"  type="submit" onClick={(e)=>{
            if(props.validateConfirm()){
              setModal(false);
              props.numberConfirm();
            }
          }}>Aceptar</Button>{' '}
          <Button color="secondary"  onClick={()=>setModal(false)}>Cancel </Button>
        </ModalFooter>
      </Modal>
      
    </span>
      
         </div>  
   }
      </CardBody>
    </Card>)
         
         
      
  
  }
  


Number.propTypes = {
  // bla: PropTypes.string,
};

Number.defaultProps = {
  // bla: 'test',
};


export default Number;
