/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col, Card, CardBody,  CardTitle, Button, Label, Modal, ModalHeader, ModalBody, Spinner, Badge} from "reactstrap";
import {Get} from '../../Api';
import { FaGoogle } from "react-icons/fa";
import { MdCached, MdAttachMoney } from "react-icons/md";
import { FaRegHeart } from "react-icons/fa";



class Header extends React.Component {

  state = {
    modal: false,
    modalAfter: false
  }

  authorize = (e) => {
    
    let url = "https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/adsense.readonly&access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri="+process.env.REACT_APP_OAUTH2_REDIRECT+"&client_id="+process.env.REACT_APP_CLIENT_ID;
    
    window.open(url, "_blank");
    this.setState({
      modal:false
    });

  };

  componentDidMount = () => {
   
    this.esHealth();
    this.refreshAdsense();
    
  }

  esHealth = () => {
   
    Get("/admin/eshealth")
    .then(resp=>{
      
      this.setState({
         cluseterHealth: resp,
         loading: false
       });
      
    })
   
  }




  refresh = (e) => {
    this.setState({
      loading: true
    });

    this.esHealth();
    this.refreshAdsense();
  
  }



  refreshAdsense = (e) => {
    
    Get("/admin/earnings/startOfMonth/today")
    .then(resp=>{
       this.setState({
         moneyMonth: resp.money,
         clicksMonth: resp.clicks,
         impressionsMonth: resp.impressions,
         pageViewsMonth: resp.pageViews,
         loading: false
       });
    })
    .catch(err=>{
      if(err.response && err.response.status===401 && !this.state.modalAfter) {
        this.setState({
          modal:true
        });
      }
     
      this.setState({
        loading:false
      });
    });

    //7 days
    Get("/admin/earnings/today-1d/today-1d")
    .then(resp=>{
       this.setState({
        money7: resp.money,
        clicks7: resp.clicks,
        impressions7: resp.impressions,
        pageViews7: resp.pageViews,
        loading: false
       })
       
    })
    .catch(err=>{
        this.setState({
          loading:false
        });;
    });

    //today
    Get("/admin/earnings/today/today")
    .then(resp=>{
       this.setState({
         moneyToday: resp.money,
         clicksToday: resp.clicks,
         impressionsToday: resp.impressions,
         pageViewsToday: resp.pageViews,
         loading: false
       })
       
    })
    .catch(err=>{
      this.setState({
        loading:false
      });
     });
    
  };

  render() {
 let health = this.state.cluseterHealth? this.state.cluseterHealth.health:null;

    return (
      <>
        <div className="header bg-gradient-info2 pb-5 pt-5">
          <Container fluid>
            <div className="header-body">

              {this.state.loading && <div className="text-center" ><Spinner type="grow" color="danger"/></div>}
              {/* Card stats */}

             
              <Row className="mb-2 ">

      
              <Col>
            {this.state.cluseterHealth && this.state.cluseterHealth.health && <Badge className="p-2" style={{background:health, color:(health==="yellow"?"black":"white")}}><FaRegHeart/> {this.state.cluseterHealth.health}</Badge>}  
            </Col>
            
                <Col className ="text-right">
                  <Button color="primary" onClick={(e)=>this.refresh(e)}><MdCached/> Refrescar</Button>
                </Col>
            
              </Row>

           

              {!this.state.loading && (
              <Row>
                <Col lg="6" xl="4">
                  <Card className="card-stats mb-4 mb-xl-0 rounded-lg">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            HOY
                          </CardTitle>
                                <span className="h2 font-weight-bold mb-0">${this.state.moneyToday}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <MdAttachMoney/>
                          </div>
                        </Col>
                      </Row>
                      <div className="mt-3 mb-0 text-muted text-sm">
                        <table className="w-100">
                          <thead>
                          <tr>
                            <th className="text-success">Clicks</th>
                            <th className="text-info-cyan">Imp.</th>
                            <th className="text-danger">Visitas</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <td className="text-success">{this.state.clicksToday}</td>
                            <td className="text-info">{this.state.impressionsToday}</td>
                            <td className="text-danger">{this.state.pageViewsToday}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="4">
                  <Card className="card-stats mb-4 mb-xl-0 rounded-lg">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            AYER
                          </CardTitle>
                                <span className="h2 font-weight-bold mb-0">${this.state.money7}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <MdAttachMoney/>
                          </div>
                        </Col>
                      </Row>
                      <div className="mt-3 mb-0 text-muted text-sm">
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th className="text-success">Clicks</th>
                              <th className="text-info-cyan">Imp.</th>
                              <th className="text-danger">Visitas</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-success">{this.state.clicks7}</td>
                              <td className="text-info">{this.state.impressions7}</td>
                              <td className="text-danger">{this.state.pageViews7}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="4">
                  <Card className="card-stats mb-4 mb-xl-0 rounded-lg">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            ESTE MES
                          </CardTitle>
                                <span className="h2 font-weight-bold mb-0">${this.state.moneyMonth}</span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <MdAttachMoney/>
                          </div>
                        </Col>
                      </Row>
                      <div className="mt-3 mb-0 text-muted text-sm">
                        <table className="w-100">
                        <thead>
                          <tr>
                            <th className="text-success">Clicks</th>
                            <th className="text-info-cyan">Imp.</th>
                            <th className="text-danger">Visitas</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <td className="text-success">{this.state.clicksMonth}</td>
                            <td className="text-info">{this.state.impressionsMonth}</td>
                            <td className="text-danger">{this.state.pageViewsMonth}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>)}
            </div>
          </Container>
        </div>
        <Modal style={{  fontweight: "bold" }} isOpen={this.state.modal} >
          <ModalHeader  className = "text-center" toggle={()=>this.setState({modal:false})} >REQUIERE AUTORIZACION</ModalHeader>
          <ModalBody className = "text-center">
            <Label>La funcionalidad requiere autorización de Google. Luego de autorizar debe presionar el botón Refrescar.</Label>
            <Button color="primary" onClick={(e)=>this.authorize(e)}><FaGoogle/> Autorizar</Button>
            <Button color="secondary" onClick={()=>this.setState({modal:false, modalAfter:true})}>Recordar después</Button>
            <Button color="secondary" onClick={()=>this.setState({modal:false})}>Cancelar</Button>
          </ModalBody>
      </Modal>
      </>
    );
  }
}

export default Header;
