import React, { PureComponent } from 'react';
import { Col, Container, Row, FormGroup, Input, Form, Button, Spinner } from 'reactstrap';
import Profile from '../../components/Usuarios/Profile'
import Administration from '../../components/Usuarios/Administration'
import PronosticosRecientes from '../../components/Usuarios/PronosticosRecientes'
import MensajesRecientes from '../../components/Usuarios/MensajesRecientes';
import { MdSearch } from "react-icons/md";
import {Get ,Put} from '../../Api'
import Qs from 'querystring'
//import { Test } from './Usuario.styles';

class Usuario extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      status: 200,
      statusBlocked:200,
      loading: false,
      loadingBlocked: false,
      selectCause:"-1",
      statusTag:200
    };
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ?target.checked :target.value;

    this.setState({
      [name]: value
    });
  }


  resetOptions = () =>{
     
   
   this.setState({
   bloquearPronostico:0,
   bloquearDispositivo:0,
   enviarNotificacion:0,
   selectCause:"-1"
  });


  }

  refrescar = (e) =>{

    e.preventDefault();

    this.resetOptions();
    this.setState({
      loading:true
    });
    Get("/admin/user/" + this.state.nick)
    .then(resp=>{
     
      this.setState({
        loading:false,
        profileData:resp,
        status: 200       
       });


    }).catch(error=>{
      this.setState({
        status:error.response?error.response.status:500,
        loading: false
      })

    });
    
 }


  blocked = (e) =>{
    e.preventDefault();
    
    let params ={
        "blockedAll":this.state.bloquearPronostico?1:0,
        "blockedDevice":this.state.bloquearDispositivo?1:0,
        "sendNotification":this.state.enviarNotificacion?1:0,
        "reason":this.state.selectCause
    }

    if(this.state.selectCause==="-1"){
      alert("Causa requerida");
      return;
    }

    this.setState({
      loadingBlocked:true
    });
  

    Put("/admin/blockuser/"+ this.state.profileData.profile.nick +'?'+Qs.stringify(params) , this.state.profileData.profile)
    .then( (resp) =>{

        let dataAux = this.state.profileData;
        dataAux.profile=resp;
        
        this.setState({
          profileData:dataAux,
          loadingBlocked:false,
          bloquearPronostico:false,
          statusBlocked:200
        });
        
    }).catch(error=>{
      this.setState({
        statusBlocked:error.response?error.response.status:500,
        loadingBlocked: false
      })

    });
    
      
  }


  updateTag = () =>{
    
    this.setState({
      loading:true
    });
  
    Put("/admin/updatetag/"+ this.state.profileData.profile.nick  , this.state.profileData.profile)
    .then( (resp) =>{

        let dataAux = this.state.profileData;
        dataAux.profile=resp;
        
        this.setState({
          profileData:dataAux,
          loading:false,
          statusTag:200
          
        });
 
        
    }).catch(error=>{
      this.setState({
        statusTag:error.response?error.response.status:500,
        loading: false
      })

    });
    
      
  }

  unblocked = (e) =>{
    e.preventDefault();
    this.resetOptions();

    this.setState({
      loadingBlocked:true
    });
      Put("/admin/unblockuser/"+ this.state.profileData.profile.nick, this.state.profileData.profile)
      .then( (resp) =>{
          
          let dataAux = this.state.profileData;
          dataAux.profile=resp;
          
          this.setState({
            profileData:dataAux,
            loadingBlocked:false,
            statusBlocked:200
          });
         
      }).catch(error=>{
        this.setState({
          statusBlocked:error.response?error.response.status:500,
          loadingBlocked: false
        })
  
      }); 
    }
   search = <Container className="m-2 mt-5" fluid>
     
          <Row  className="mb-2">
            <Col >
              <Form inline onSubmit={(e)=>this.refrescar(e)}>
                <FormGroup className= "mr-2" >

                  <Input type="search" name="nick" id="nickId" placeholder="Nick"  onChange={(event)=> this.handleInputChange(event)} />

                </FormGroup>
                <Button onClick={(e)=>this.refrescar(e)} color="primary"> <MdSearch/> Buscar</Button>
              </Form>
            </Col>

          </Row>
          <hr/>

          </Container>;

  render() {
    
    let result;
    if(this.state.loading) {
      
      result =  <div className="text-center p-5" ><Spinner type="grow" color="primary"/></div>
      
     }
     else if( this.state.status===200 && this.state.profileData !== undefined) {
      result = (<Container fluid className=" pl-5 pr-5 pt-2 pb-5 ">

           <Row className="mt-2">
           
             <Col>
               <Profile profile= {this.state.profileData.profile} postalesCount={this.state.profileData.postalesCount} updateTag={this.updateTag} statusTag={this.state.statusTag}/>
             </Col>
 
             <Col>
               <Administration  
                  loadingBlocked = {this.state.loadingBlocked} 
                  blocked={this.blocked} 
                  profile= {this.state.profileData.profile}  
                  unblocked={this.unblocked} 
                  handleInputChange={this.handleInputChange}
                  statusBlocked={this.state.statusBlocked}
                  />
             </Col>
 
           </Row>
 
           <Row className="mt-5" >
             <Col xl="6">
               <PronosticosRecientes pronostics = {this.state.profileData.pronosticsList}/>
             </Col>
 
             <Col xl="6">
               <MensajesRecientes messages = {this.state.profileData.messagesList}/>
             </Col>
           </Row>
           </Container>)
         
       }
       else if(this.state.status===404){
        result = <h1 className="text-center p-5">El usuario {this.state.nick} no existe</h1>
       }
       else if(this.state.status===500){
        result = <h1 className="text-center p-5">Error cargando los datos</h1>
       }
    
    return (<div>
      
      {this.search}
      {result}
    
    </div>)
    

    }

}

Usuario.propTypes = {
  // bla: PropTypes.string,
};

Usuario.defaultProps = {
  // bla: 'test',
};


export default Usuario;
