import React from "react";
import Qs from 'querystring';
import {Get} from 'Api';

class OAuth2Callback extends React.Component {

    constructor(props) {
        super(props)
        
        this.handleToken();

    }

    handleToken = () => {
        let search = this.props.location.search;
        let query = Qs.parse(search.slice(1));
        Get("/admin/oauth2?code="+(query.code))
        .then(()=>{
            window.close();
        })
        .catch((err)=>{
            console.log(err);
            window.close();
        });
        
        
    }

    render() {
        return <></>
    }

} 

export default OAuth2Callback;